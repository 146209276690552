<template>
  <div>
    <div class="content">
      <ul>
        <div class="center">
          <li>
            <img src="../../static/image/lastesInform.jpg" height="253" width="556"/>
            <div class="fontDiv">
                <span class="title">
                  从全球视野看全球化设计
                </span>
              <span class="many">
                在让UI设计师产出高保真设计稿之前，产品经理需要绘制线框图，来向UI设计师传递网站布局结构、产品内容、信息化层次、界面功能、用户行为等信息。线框图没有严格的规定或秩序要遵守，但一定要清晰具体地描述布局细节，为设计师提供项目的概述。
              </span>
            </div>
          </li>
          <li>
            <img src="../../static/image/lastesInform.jpg"" height="253" width="556"/>
            <div class="fontDiv">
                <span class="title">
                  从全球视野看全球化设计
                </span>
              <span class="many">
                在让UI设计师产出高保真设计稿之前，产品经理需要绘制线框图，来向UI设计师传递网站布局结构、产品内容、信息化层次、界面功能、用户行为等信息。线框图没有严格的规定或秩序要遵守，但一定要清晰具体地描述布局细节，为设计师提供项目的概述。
              </span>
            </div>
          </li>
          <li>
            <img src="../../static/image/enterpnse.jpg" height="253" width="556"/>
            <div class="fontDiv">
                <span class="title">
                  从全球视野看全球化设计
                </span>
              <span class="many">
                在让UI设计师产出高保真设计稿之前，产品经理需要绘制线框图，来向UI设计师传递网站布局结构、产品内容、信息化层次、界面功能、用户行为等信息。线框图没有严格的规定或秩序要遵守，但一定要清晰具体地描述布局细节，为设计师提供项目的概述。
              </span>
            </div>
          </li>
          <li>
            <img src="../../static/image/enterpnse.jpg" height="253" width="556"/>
            <div class="fontDiv">
                <span class="title">
                  从全球视野看全球化设计
                </span>
              <span class="many">
                在让UI设计师产出高保真设计稿之前，产品经理需要绘制线框图，来向UI设计师传递网站布局结构、产品内容、信息化层次、界面功能、用户行为等信息。线框图没有严格的规定或秩序要遵守，但一定要清晰具体地描述布局细节，为设计师提供项目的概述。
              </span>
            </div>
          </li>
        </div>

      </ul>
    </div>
    <!--    分页-->
    <div class="pageInfo">
      <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnterpriseDynamics"
}
</script>

<style scoped lang="less">
.content{
  ul{
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    .center{
      width: 100%;
      /* margin: 0 auto; */
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      li{
        padding-left: 20px;
        margin: 0px 21px 100px 0;
        width: 550px;
        display: inline-block;
        position: relative;
        .fontDiv{
          transition-duration:1s;
          width: 85%;
          background-color: #Fff;
          position: absolute;
          top: 200px;
          padding: 0 30px;
          span{
            display: block;
            color: rgb(45,45,47);
            font-size: 18px;
          }
          .title{
            margin: 15px 0px;
          }
          .many{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            color: rgb(102,102,102) !important;
          }
        }
      }
    }
  }

}
.pageInfo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 35px auto;
}
.fontDiv:hover{
  box-shadow: 2px 4px 20px #ccc;
  cursor:pointer;
}
//img{
//  transition-duration:1s;
//}
//img:hover{
//  height:300px;
//  width: 600px;
//}
</style>
