import { render, staticRenderFns } from "./latestInformation.vue?vue&type=template&id=3138acac&scoped=true&"
import script from "./latestInformation.vue?vue&type=script&lang=js&"
export * from "./latestInformation.vue?vue&type=script&lang=js&"
import style0 from "./latestInformation.vue?vue&type=style&index=0&id=3138acac&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3138acac",
  null
  
)

export default component.exports